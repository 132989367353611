import React, { Component } from 'react';
import characterData from '../data/characters.js';
import AccordionList from '../components/AccordionList/AccordionList.js';
import Layout from '../components/Layout.js';
import { Link } from "gatsby";
import "../styles/Cast.css";

let charName = null;

function Character({item}) {
  function renderImg() {
    if (!item.thumb) return null;
    return (<img className="charThumb" alt="Character portrait" src={item.thumb}/>)
  }
  return (
      <div className="charWrapper">
        <div className="charThumbSideText">
          {renderImg()}
          <div>
            <p className="charAbilities"><span>Abilities: </span>{item.abilities}</p>
            <p className="charRace"><span>Race: </span>{item.race}</p>
          </div>
        </div>
        <p className="charDesc">{item.description}</p>
        <Link to={`/archive?char=${item.name}`}>Tagged pages</Link>
      </div>
  )
}

function CharacterFolder({item}) {
  function shouldExpandCharacter(char) {
    if (typeof (charName) !== 'string') return false;

    return (char.name === charName);
  }

  function renderThumb() {
    if (!item.thumb) return null;
    return (<img className="charThumb" alt="" src={item.image}/>)
  }

  return (
      <div className="charFolder">
        {renderThumb()}
        <AccordionList list={item.characters} shouldExpand={shouldExpandCharacter} SlotComponent={Character}/>
      </div>
  )
}

class CharacterList extends Component {
  constructor(props) {
    super(props);
    //Terrible query parsing...
    charName = (props.location.search) ? props.location.search.replace('?char=', '') : null;
  }

  shouldExpandFolder(folder, folderIx) {
    if (typeof (charName) !== 'string') return folderIx === 0;

    return (folder.characters.find(char => char.name === charName));
  }

  componentDidMount() {
    if (typeof (charName) === 'string') {
      window.setTimeout(() => {
        const targetEl = document.getElementById(charName);
        if (targetEl) {
          document.documentElement.scrollTop = targetEl.getBoundingClientRect().y;
        }
      }, 20);
    }
  }

  render() {
    return (
        <Layout>
          <div className="content">
            <AccordionList list={characterData} shouldExpand={this.shouldExpandFolder} SlotComponent={CharacterFolder}/>
          </div>
        </Layout>
    );
  }
}

export default CharacterList;

/*
Needed validation: Character names should be unique, and it should be able to find a character and tell you it's index in both arrays
 */