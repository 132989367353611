import images from './thumbs';

export default [{
  name: "Central Characters",
  characters: [{
    name: "Shimurli",
    race: "Half-Lendurran, half-Kilonji",
    abilities: "Shown in the prologue to be a telekinetic, although she hasn't been able to use it since. Also moderately proficient in the Lendurran womens' sword style, which prioritizes speed and superior agility.",
    description: "Small in stature but not in her attitude, which often comes off as imperious. After an unfortunate incident when she was 6 years old, she was lost to her own family and adopted by Lendurrans. A spell affects her memory, preventing her from remembering anything important about her past, and is possibly connected with why she is unreasonably convinced that her birth parents abandoned her. She has had difficulty adapting to Lendurran culture and might even be described as desperately unhappy...."
  },
  {
    name: "Toldas",
    race: "Lendurran",
    abilities: "Physically powerful; clear-sighted and hard to fool.",
    description: "Shimurli's Lendurran brother by adoption, with whom she shares a close relationship. Unfailingly kind and empathetic, Toldas always envisioned himself living out his life peacefully on a little farm and would feel unqualified to do much else. Little does he imagine how his comfortable life plan is about to get derailed."
  }]
}, {
  name: "Supporting Characters",
  characters: [{
    name: "Fevlana",
    race: "Kilonji",
    abilities: "Kilonji illusionist, and since the events of the prologue, a first generation Arysenthine.",
    description: "A member of a group with an unpopular ideology (the Huleshi), Fevlana's main concern was to promote understanding and fair treatment of her people, although she now regrets actions she took with her brother that helped him become the king of Kivelen. It is believed by interested parties that she went into hiding with the previous king's son who is widely regarded to be the rightful king of Kivelen.",
    thumb: images["Fevlana"]
  }]
}, {
  name: "Antagonists",
  characters: [{
    name: "Gora",
    race: "Kilonji",
    abilities: "A Wizard, apparently.",
    description: "Leader of a radical sect of the Huleshi. Known for taking their ideology to the extreme and using firebrand rhetoric to ignite a crusade against the non-Kilonji races. Her goal is to annihilate or expel other races from Maydijira, which she views as the Kilonji's divinely appointed homeland.",
    thumb: images["Gora"]
  }, {
    name: "Lureth",
    race: "Kilonji",
    abilities: "Persuasive and a convincing liar.",
    description: "Shimurli's uncle who cajoled and connived his way from baron to king of Kivelen.",
    thumb: images["Lureth"]
  }]
}]

/* For starters I need

Shimurli
Fevlana
Toldas
Lor + spouse
--- There are other minor characters who may eventually get their own pages,
 */